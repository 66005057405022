import React from "react";
import {
   BrowserRouter as Router,
   Route,
   Routes,
   Navigate,
} from "react-router-dom";
import Splash from "./pages/Splash";
import SignUp from "./pages/SignUp";
import ForgotPasswordForm from "./pages/Forgot";

function App() {
   return (
      <Router>
         <Routes>
            <Route path='/' element={<Navigate to='/pages/Splash' replace />} />
            <Route path='/pages/Splash' element={<Splash />} />
            <Route path='/signup' element={<SignUp />} />
            <Route path='*' element={<Navigate to='/pages/Splash' replace />} />
            <Route path='/forgot' element={<ForgotPasswordForm />} />
         </Routes>
      </Router>
   );
}

export default App;
