import React, { useState } from "react";
import {
   TextField,
   Button,
   MenuItem,
   FormControlLabel,
   Checkbox,
   Typography,
   Container,
   Paper,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Header from "../components/Header";

const countries = [
   { label: "Panama", value: "PA" },
   // Add all countries here
   { label: "United States", value: "US" },
   { label: "Canada", value: "CA" },
   // etc.
];

const CreateProfileForm = () => {
   const [showPassword, setShowPassword] = useState(false);

   const handleClickShowPassword = () => setShowPassword((prev) => !prev);

   return (
      <Container maxWidth='sm'>
         <Header />
         <Paper elevation={31} style={{ padding: "20px", marginTop: "20px" }}>
            <Typography variant='h5' align='center' gutterBottom>
               Create Profile
            </Typography>
            <TextField
               fullWidth
               label='Name'
               margin='normal'
               variant='outlined'
            />
            <TextField
               fullWidth
               label='Email'
               margin='normal'
               variant='outlined'
               type='email'
            />
            <TextField
               fullWidth
               select
               label='Country'
               margin='normal'
               variant='outlined'
               defaultValue='PA'
            >
               {countries.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                     {option.label}
                  </MenuItem>
               ))}
            </TextField>
            <TextField
               fullWidth
               label='Password'
               margin='normal'
               variant='outlined'
               type={showPassword ? "text" : "password"}
               InputProps={{
                  endAdornment: (
                     <InputAdornment position='end'>
                        <IconButton
                           aria-label='toggle password visibility'
                           onClick={handleClickShowPassword}
                           edge='end'
                        >
                           {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                     </InputAdornment>
                  ),
               }}
            />
            <Typography
               variant='body2'
               align='center'
               style={{ marginTop: "20px", fontSize: "10px" }}
            >
               By continuing, I confirm that I have read & agreed to the Terms
               and Conditions and Privacy Policy.
            </Typography>
            <Button
               fullWidth
               variant='contained'
               color='primary'
               style={{ marginTop: "20px" }}
            >
               Register
            </Button>
         </Paper>
      </Container>
   );
};

export default CreateProfileForm;
