import { IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

const Header = () => {
   const navigate = useNavigate();

   const handleBackClick = () => {
      navigate(-1); // This will navigate back one page in the history stack
   };
   return (
      <div>
         <IconButton sx={{ color: "black" }} onClick={handleBackClick}>
            <ArrowBackIcon sx={{ fontSize: "48px" }} />
         </IconButton>
         <img
            src={`${process.env.PUBLIC_URL}/assets/img/logo-white-sm.png`}
            alt='Logo'
            style={{ display: "block", marginTop: "8px" }}
         />
      </div>
   );
};
export default Header;
