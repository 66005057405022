import React, { useState } from "react";
import { TextField, Button, Typography, Container, Paper } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Header from "../components/Header";

const ForgotPasswordForm = () => {
   const [email, setEmail] = useState("");
   const [showPassword, setShowPassword] = useState(false);
   const [newPassword, setNewPassword] = useState("");

   const handleClickShowPassword = () => setShowPassword((prev) => !prev);

   const handleEmailChange = (event) => setEmail(event.target.value);
   const handleNewPasswordChange = (event) =>
      setNewPassword(event.target.value);

   return (
      <Container maxWidth='sm'>
         <Header />
         <Paper elevation={31} style={{ padding: "20px", marginTop: "20px" }}>
            <Typography variant='h5' align='center' gutterBottom>
               Forgot Password
            </Typography>
            <TextField
               fullWidth
               label='Email'
               margin='normal'
               variant='outlined'
               type='email'
               value={email}
               onChange={handleEmailChange}
            />

            <Typography
               variant='body2'
               align='center'
               style={{ marginTop: "20px", fontSize: "10px" }}
            >
               By continuing, I confirm that I have read & agreed to the Terms
               and Conditions and Privacy Policy.
            </Typography>
            <Button
               fullWidth
               variant='contained'
               color='primary'
               style={{ marginTop: "20px" }}
            >
               Reset Password
            </Button>
         </Paper>
      </Container>
   );
};

export default ForgotPasswordForm;
