// App.tsx
import React from "react";
import "../App.css";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

function Splash() {
   const navigate = useNavigate();
   return (
      <div className='splash-container'>
         <img
            src={`${process.env.PUBLIC_URL}/assets/img/logo-white.png`}
            alt='Logo'
            className='logo'
         />
         <div className='button-container'>
            <Button
               onClick={() => navigate("/signup")}
               className='btn btn-primary'
            >
               Sign Up
            </Button>
            <Button
               onClick={() => navigate("/forgot")}
               className='btn btn-primary'
            >
               Forgot Password
            </Button>
         </div>
      </div>
   );
}

export default Splash;
